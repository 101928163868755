<template>
  <div class="app-form">
    <b-tabs>
      <b-tab-item label="Таблица">
        <!--     <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">ID</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input class="input" type="text" :disabled="true" v-model="model.id">
              </div>
            </div>
          </div>
          </div> -->
        <template v-for="(value, name) in model">
          <div class="field is-horizontal" v-bind:key="name">
            <div class="field-label is-normal">
              <label class="label">{{name}}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input" type="text" :disabled="true" :value="value">
                </div>
              </div>
            </div>
          </div>
        </template>
        <!--     <div class="field is-horizontal">
          <div class="field-label">
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <button class="button is-primary" @click="doUpdate()">
                Сохранить
                </button>
              </div>
            </div>
          </div>
          </div> -->
      </b-tab-item>
      <b-tab-item label="JSON">
        <div class="app-json">
          <vue-json-pretty
            :path="'res'"
            :data="model">
          </vue-json-pretty>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>
<script>
  import { getDish } from '@/api/dish'
  import { state_yesno, state_show } from '@/utils'
  import VueJsonPretty from 'vue-json-pretty'
  import 'vue-json-pretty/lib/styles.css'
  export default {
    name: 'DishForm',
    components: {
      VueJsonPretty
    },
    props: {
      //
    },
    data: function () {
      return {
        state_yesno: state_yesno,
        state_show: state_show,
        model: {
        }
      }
    },
    created: function() {
      //
    },
    computed: {
      //
    },
    beforeMount: function(){
      //
    },
    mounted: function(){
      // let $this = this
  
      getDish(this.$route.params.id, this.$store.state.jwt_user)
        .then(response => {
          let data = response.data;
          
          let result = data.data
          this.model = result
  
        })
        .catch(
          this.model = {}
        )
    },
    methods: {
      //
    }
  }
</script>
<style scoped>
  .app-form {
  text-align: left;
  }
</style>
<style></style>